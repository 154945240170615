import React from "react"
import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import { SimplePage } from "../components/SimplePage"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <SimplePage>
      <Container className="d-flex flex-column">
        <Row className="align-items-center justify-content-center no-gutters">
          <Col md="5" lg="4" className="text-center py-8">
            <h1 className="display-3 font-weight-bold">Page not found</h1>

            <p className="mb-5 text-muted">
              It appears the page you were looking for couldn’t be found.
            </p>

            <Button href="/" variant="primary">
              Back to home
            </Button>
          </Col>
        </Row>
      </Container>
    </SimplePage>
  </Layout>
)

export default NotFoundPage
